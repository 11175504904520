import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Index from "../pages/Index";
import Form from "../pages/RegisterForm";
import FormRedirect from "../pages/RegisterFormRedirect";
import AffiliateFormRedirect from "../pages/RegisterAffiliateFormRedirect";
import RegisterAffiliateSuccess from '../pages/RegisterAffiliateSuccess';

export default function Routes(props) {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Index
            apiUrl={props.apiUrl}
            apiToken={props.apiToken}
            pageSetting={props.pageSetting}
          />
        </Route>
        <Route exact path={["/form"]}>
          <Form apiUrl={props.apiUrl} apiToken={props.apiToken} />
        </Route>
        <Route exact path={["/form-redirect"]}>
          <FormRedirect
            dnsRedirect={props.dnsRedirect}
            apiUrl={props.apiUrl}
            apiToken={props.apiToken}
          />
        </Route>
        <Route exact path={["/affiliate-form-redirect"]}>
          <AffiliateFormRedirect
            dnsRedirect={props.dnsRedirect}
            apiUrl={props.apiUrl}
            apiToken={props.apiToken}
            affiliateUrl={props.affiliateUrl}
            affiliateApiToken={props.affiliateApiToken}
          />
        </Route>
        <Route exact path="/affiliate-form-redirect/success">
          <RegisterAffiliateSuccess />
        </Route>
      </Switch>
    </Router>
  );
}
