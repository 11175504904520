import React, { useEffect } from "react";
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/main.scss";
import Routes from "./config/Routes";
import PublicThemeProvider from "./config/PublicThemeProvider";

const signInTheme = createTheme({
  props: {
    // Name of the component ⚛️
    MuiTextField: {
      // The default props to change
      variant: "outlined",
      margin: "normal",
      fullWidth: true,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Public(props) {
  const classes = useStyles();

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (props.flash.notice) {
      toast.info(props.flash.notice);
    }
    if (props.flash.notice) {
      toast.warning(props.flash.alert);
    }
  }, []);

  return (
    <div id="main-div">
      <ToastContainer autoClose={4000} />
      <PublicThemeProvider>
        <Routes
          affiliateUrl={props.affiliate_url}
          affiliateApiToken={props.affiliate_api_token}
          dnsRedirect={props.dns_redirect}
          apiUrl={props.api_url}
          apiToken={props.api_token}
          pageSetting={props.page_setting}
        />
      </PublicThemeProvider>
    </div>
  );
}
