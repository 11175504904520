import React from "react";
import { IMaskInput } from "react-imask";
import IMask from "imask";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  let mask;
  let extra = {};

  switch (props.mask) {
    case "phone":
      mask = "(00) 00000-0000";
      break;
    case "date":
      mask = Date;
      extra = {
        pattern: "d{/}`m{/}`Y",
        format: function (date) {
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();

          if (day < 10) day = "0" + day;
          if (month < 10) month = "0" + month;

          return [day, month, year].join("/");
        },
        // define str -> date convertion
        parse: function (str) {
          var dayMonthYear = str.split("/");
          return new Date(
            dayMonthYear[2],
            dayMonthYear[1] - 1,
            dayMonthYear[0]
          );
        },
      };
      break;
    case "cpf":
      mask = "000.000.000-00";
      break;

    case "email":
      // mask = /^[\w.]+@([\w]+.)+[\w]{2,4}$/
      // /^\w+$/
      break;
    default:
      break;
  }

  return (
    <IMaskInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onAccept={
        // Advance to the next field, if there is one, when mask is completed
        (value, mask) => {
          if (mask.masked.isComplete) {
            const form = mask.el.input.form;
            const index = Array.prototype.indexOf.call(form, mask.el.input);
            if (form.elements.length - 1 > index) {
              form.elements[index + 1].focus();
            }
          }
        }
      }
      mask={mask}
      {...extra}
      // placeholderChar={"\u2000"}
      // showMask={false}
    />
  );
}

function routeMask(maskLabel) {
  let mask;
  let extra;

  switch (maskLabel) {
    case "cpf":
      mask = "000.000.000-00";
      break;
    case "cartao_sus":
      mask = "000 0000 0000 0000";
      break;
    case "email":
      mask = /^[a-zA-Z0-9|\s|@|\\.|\-|\_]+$/;
      break;
    case "phone":
      mask = "(00) 00000-0000";
      break;
    case "date":
      mask = "00/00/0000";
      extra = {
        lazy: false,
        overwrite: true,
        validate: (value, masked) => {
          const errors = [];

          if (value.match(/^\d\d\/\d\d\/\d\d\d\d$/) != null) {
            const currentDate = moment(value, "DD-MM-YYYY");
            if (!currentDate.isValid()) errors.push("invalid");
            if (!currentDate.isBetween("1900-01-01", undefined))
              errors.push("out_of_range");
          }

          return errors.length === 0;
        },
      };
      break;
    case "zipCode":
      mask = "00000-000";
      break;
    default:
      break;
  }

  return { mask, extra };
}

export function TextMaskPhone(props) {
  return <TextMaskCustom {...props} mask="phone" />;
}
export function TextMaskDate(props) {
  return <TextMaskCustom {...props} mask="date" />;
}
export function TextMaskEmail(props) {
  return <TextMaskCustom {...props} mask="email" />;
}
export function TextMaskCpf(props) {
  return <TextMaskCustom {...props} mask="cpf" />;
}

export function legacyMaskCPF(elementRef) {
  const { mask, extra = {} } = routeMask("cpf");

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskPhone(elementRef) {
  const { mask, extra = {} } = routeMask("phone");

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskEmail(elementRef) {
  const { mask, extra = {} } = routeMask("email");
  return IMask(elementRef.current, { mask, ...extra });
}
