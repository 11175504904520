import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  Button,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  legacyMaskCPF,
  legacyMaskPhone,
} from "../components/RegistrationForm/TextMaskCustom";
import axios from "axios";
import { func } from "prop-types";
import casaLogo from '../images/casa-de-apostas-logo.png';
import afiliadosLogo from '../images/afiliados-logo.svg';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    padding: "20px",
    margin: "auto",
    fontFamily: "Verdana, Geneva, sans-serif",
    backgroundColor: "#f3f3f3",
    borderBottomStyle: "none",
    borderColor: "transparent",
    borderLeftStyle: "none",
    borderRadius: "10px",
    borderRightStyle: "none",
    borderTopStyle: "none",
    borderWidth: "1px",
    maxWidth: "80%",
    minWidth: "320px",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    color: "white",
  },
  button: {
    backgroundColor: "#333333",
    color: "white",
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1rem',
    margin: '10px 0px 15px',
  },
  description: {
    fontSize: '1rem',
    margin: '0px 0px 30px',
  }
}));

export default function RegisterAffiliateSuccess(props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 style={{ color: '#333333', textAlign: 'center' }}>Sucesso!</h1>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center", color: '#333333', marginBottom: 30 }}>
            <div style={{ margin: "0 auto", background: 'white', borderRadius: 4, width: '90%', padding: 15 }}>
              <div style={{ background: '#333333', height: 60, width: '100%', borderRadius: 4, textAlign: 'center' }}>
                <img src={casaLogo} style={{ height: 60 }} />
              </div>
              <div style={{ textAlign: 'left' }}>
                <p className={classes.title}>Ir para o site - Casa de Apostas</p>
                <p className={classes.description}>Faça login no Site Casa de Apostas e jogue com a gente.</p>
              </div>
              <a href="https://casadeapostas.com/" target="_blank" style={{ textDecoration: 'none' }}>
                <Button fullWidth classes={{ root: classes.button }}>Site - Casa de Apostas</Button>
              </a>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center", color: '#333333', marginBottom: 30 }}>
            <div style={{ margin: "0 auto", background: 'white', borderRadius: 4, width: '90%', padding: 15 }}>
              <div style={{ background: '#333333', height: 60, width: '100%', borderRadius: 4, textAlign: 'center' }}>
                <img src={afiliadosLogo} style={{ height: 40, marginTop: 8 }} />
              </div>
              <div style={{ textAlign: 'left' }}>
                <p className={classes.title}>Plataforma de afiliados</p>
                <p className={classes.description}>Consulte seus ganhos, usuários indicados e muito mais</p>
              </div>
              <a href="https://afiliados.casadeapostas.com/" target="_blank" style={{ textDecoration: 'none' }}>
                <Button fullWidth classes={{ root: classes.button }}>Plataforma de Afiliados</Button>
              </a>
            </div>
          </Grid>
        </Grid>
      </main>
    </React.Fragment>
  );
}
