import React, { useEffect, useState, useRef } from "react";
import {
  TextField,
  Grid,
  InputLabel,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Select,
  Button,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  legacyMaskCPF,
  legacyMaskPhone,
} from "../components/RegistrationForm/TextMaskCustom";
import axios from "axios";
import { func } from "prop-types";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    padding: "20px",
    margin: "auto",
    fontFamily: "Verdana, Geneva, sans-serif",
    backgroundColor: "#f3f3f3",
    borderBottomStyle: "none",
    borderColor: "transparent",
    borderLeftStyle: "none",
    borderRadius: "10px",
    borderRightStyle: "none",
    borderTopStyle: "none",
    borderWidth: "1px",
    maxWidth: "80%",
    minWidth: "320px",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    color: "white",
  },
  input: {
    marginBottom: 15,
    color: "green",
    "& .MuiFormLabel-root": { color: "grey" },
    "& .MuiInputBase-root": { color: "grey", height: "2.5rem" },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey !important" },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      borderColor: "grey !important",
    },
    "& .MuiInputLabel-outlined": {
      zIndex: 1,
      transform: "translate(14px, 13px) scale(1)",
      pointerEvents: "none",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      zIndex: 1,
      transform: "translate(14px, -6px) scale(0.75) !important",
      pointerEvents: "none",
    },
  },
  button: {
    backgroundColor: "#6fb857",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Verdana, Geneva, sans-serif",
    border: `2px solid white`,
    padding: "0.5rem 1.25rem",
    boxSizing: "unset",
    borderRadius: 7,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.common.green,
      color: "white",
    },
  },
  backdrop: {
    zIndex: "1 !important",
    color: "#6fb857",
  },
  checkBox: {
    color: "grey",
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "green",
    },
    "& .MuiTypography-body1": {
      fontSize: "0.8rem",
    },
  },
}));

const mailformat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const nameFormat = /\b\w{2,}\s\w{2,}/;

export default function RegisterForm(props) {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    userDocumentRef: "",
    userEmailRef: "",
    userPhoneRef: "",
    userPasswordConfirmationRef: "",
    userPasswordRef: "",
  });
  const [loading, setLoading] = useState(false);
  const promocode = new URLSearchParams(location.search).get("promocode");
  // new
  const [validForm, setValidForm] = useState(false);
  const userPromoCodeRef = useRef("");
  const userNameRef = useRef("");
  const userBirthdayRef = useRef("");
  const userDocumentRef = useRef("");
  const userEmailRef = useRef("");
  const userPhoneRef = useRef("");
  const userPasswordRef = useRef("");
  const userPasswordConfirmationRef = useRef("");
  const userBonusAcceptanceRef = useRef(0);
  const [showPassword, setShowPassword] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [promocodeFromUrl, setPromocodeFromUrl] = useState(false);

  function adicionaZero(numero) {
    if (numero <= 9) return "0" + numero;
    else return numero;
  }

  let data = new Date();
  let dataFormatada =
    data.getFullYear() -
    18 +
    "-" +
    adicionaZero(data.getMonth() + 1) +
    "-" +
    adicionaZero(data.getDate());
  const minDate = "1920-01-01";

  function validateForm() {
    const ensureCondition = (condition) => {
      if (!condition) throw new Error("");
    };

    try {
      ensureCondition(userNameRef.current.value);
      ensureCondition(userDocumentRef.current.value);
      ensureCondition(userPhoneRef.current.value);
      // ensureCondition(userBonusAcceptanceRef.current.value);
      ensureCondition(userPasswordRef.current.value);
      ensureCondition(userPasswordConfirmationRef.current.value);
      ensureCondition(userBirthdayRef.current.value);
      ensureCondition(userBirthdayRef.current.value);
      ensureCondition(checkTerms);

      setValidForm(true);
    } catch (err) {
      setValidForm(false);
    }
  }

  function checkAnyFieldErros() {
    if (
      errors.userDocumentRef.length == 0 &&
      errors.userEmailRef.length == 0 &&
      errors.userPhoneRef.length == 0 &&
      errors.userPasswordRef.length == 0 &&
      errors.userPasswordConfirmationRef.length == 0
    ) {
      return true;
    }
    return false;
  }
  //
  useEffect(() => {
    legacyMaskCPF(userDocumentRef);
    legacyMaskPhone(userPhoneRef);
    userPromoCodeRef.current.value = promocode;
    setPromocodeFromUrl(promocode ? true : false);
  }, []);

  useEffect(() => {
    if (checkAnyFieldErros()) {
      validateForm();
    } else {
      setValidForm(false);
    }
  }, [errors]);

  function checkNameFormat() {
    if (
      (userNameRef.current && userNameRef.current.value.match(nameFormat)) ||
      userNameRef.current.value.length == 0
    ) {
      setErrors({ ...errors, userNameRef: "" });
    } else {
      setErrors({ ...errors, userNameRef: "Nome muito curto" });
    }
  }

  function checkDocumentFormat() {
    var desired = userDocumentRef.current.value.replace(/[^\w\s]/gi, "");
    if (
      (userDocumentRef.current && userDocumentRef.current.value.length >= 14) ||
      userDocumentRef.current.value.length == 0
    ) {
      setErrors({ ...errors, userDocumentRef: "" });
    } else {
      setErrors({ ...errors, userDocumentRef: "Formato inválido" });
    }
  }

  function checkDocumentIsValid() {
    var desired = userDocumentRef.current.value.replace(/[^\w\s]/gi, "");
    if (TestaCPF(desired)) {
      setErrors({ ...errors, userDocumentRef: "" });
      getCPFInformation();
    } else {
      var msg = desired.length === 0 ? "Campo não pode ficar em branco" : "Formato inválido";
      setErrors({ ...errors, userDocumentRef: msg });
    }
  }

  function checkBirthDate() {
    if (userBirthdayRef.current.value) {
      setErrors({ ...errors, userBirthdayRef: "" });
    } else {
      setErrors({ ...errors, userBirthdayRef: "não pode ficar vazio" });
    }
  }

  function checkEmailFormat() {
    if (
      (userEmailRef.current && userEmailRef.current.value.match(mailformat)) ||
      userEmailRef.current.value.length == 0
    ) {
      setErrors({ ...errors, userEmailRef: "" });
    } else {
      setErrors({ ...errors, userEmailRef: "Email inválido" });
    }
  }

  function checkPasswordFormat() {
    if (
      (userPasswordRef.current && userPasswordRef.current.value.length >= 7) ||
      userPasswordRef.current.value.length == 0
    ) {
      setErrors({ ...errors, userPasswordRef: "" });
    } else {
      setErrors({
        ...errors,
        userPasswordRef: "Senha deve conter 8 dígitos",
      });
    }
  }

  function checkPasswordConfirmation() {
    if (
      (!!userPasswordRef.current || !!userPasswordConfirmationRef.current) &&
      (userPasswordRef.current.value !==
        userPasswordConfirmationRef.current.value ||
        (userPasswordRef.current.value.length < 7 &&
          userPasswordRef.current.value.length > 0))
    ) {
      setErrors({
        ...errors,
        userPasswordConfirmationRef:
          "Confirmação difere de senha de 8 dígitos digitada",
      });
    } else {
      setErrors({ ...errors, userPasswordConfirmationRef: "" });
    }
  }

  function checkPhoneFormat() {
    if (
      (userPhoneRef && userPhoneRef.current.value.length >= 15) ||
      userPhoneRef.current.value.length == 0
    ) {
      setErrors({ ...errors, userPhoneRef: "" });
    } else {
      setErrors({ ...errors, userPhoneRef: "Telefone inválido" });
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeCheckTerms = () => {
    setCheckTerms(!checkTerms);
    if (userBirthdayRef.current.value) {
      setErrors({ ...errors, userBirthdayRef: "" });
    } else {
      setErrors({ ...errors, userBirthdayRef: "não pode ficar vazio" });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleButtonClick() {
    setLoading(true);
    createUser();
  }
  const handleCloseBackdrop = () => {
    setLoading(false);
  };

  function createUser() {
    //e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
    const obj = {
      password: userPasswordRef.current.value,
      bonusAcceptance: 0,//userBonusAcceptanceRef.current.value,
      promotionCode: userPromoCodeRef.current.value,
      firstName: userNameRef.current.value,
      phoneNumber: userPhoneRef.current.value,
      nationalIdentityId: userDocumentRef.current.value.replace(
        /[^\w\s]/gi,
        ""
      ),
      emailAddresses: userEmailRef.current.value,
      sendNewsLetterEmail: true,
      birthday: userBirthdayRef.current.value,
    };
    console.log({ obj });
    axios
      .post(`${props.apiUrl}api/v1/customer/register_basic.json`, obj, {
        headers: { "ca-token": props.apiToken },
      })
      .then(function (response) {
        console.log({ response });
        document.getElementById("myForm").submit();
      })
      .catch(function (error) {
        console.log(error);
        if ((error.response.status = 422)) {
          setResposeErrors(error.response.data);
        }
      })
      .then(function () {
        setLoading(false);
      });
  }

  function setResposeErrors(data) {
    if (data.errors.email) {
      setErrors({ ...errors, userEmailRef: data.errors.email });
    }
    if (data.errors.documentId) {
      setErrors({ ...errors, userDocumentRef: data.errors.documentId });
    }
  }

  function TestaCPF(strCPF) {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  function getCPFInformation() {
    setLoading(true);
    axios
      .get(
        `/api/v1/users/verify_cpf?cpf=${userDocumentRef.current.value.replace(
          /\D/g,
          ""
        )}`
      )
      .then(function (res) {
        // console.log(res)
        userNameRef.current.value = res.data?.nome;
        userBirthdayRef.current.value = res.data?.nascimento;
      })
      .catch(function (response) {
        // console.log("Erro ao consultar CPF", response);
      })
      .then(function () {
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <main className={classes.layout}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <TextField
                  className={classes.input}
                  id="userDocument"
                  name="userDocument"
                  label="CPF"
                  variant="outlined"
                  style={{ width: "100%", color: "white" }}
                  inputRef={userDocumentRef}
                  onChange={(e) => {
                    checkDocumentFormat();
                    validateForm();
                  }}
                  onBlur={checkDocumentIsValid}
                  error={!!errors.userDocumentRef}
                  helperText={
                    errors.userDocumentRef ? errors.userDocumentRef : ""
                  }
                  inputProps={{ autoFocus: true }}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <TextField
                  className={classes.input}
                  id="userName"
                  name="userName"
                  label="Nome completo"
                  variant="outlined"
                  style={{ width: "100%", color: "white" }}
                  inputRef={userNameRef}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    checkNameFormat();
                    validateForm();
                  }}
                  error={!!errors.userNameRef}
                  helperText={errors.userNameRef ? errors.userNameRef : ""}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <TextField
                  // required
                  className={classes.input}
                  id="birthday"
                  name="birthday"
                  label="Aniversário"
                  variant="outlined"
                  style={{ width: "100%", color: "white" }}
                  inputRef={userBirthdayRef}
                  // error={!!errors.userBirthdayRef}
                  // helperText={
                  //   errors.userBirthdayRef ? errors.userBirthdayRef : ""
                  // }
                  onChange={(e) => {
                    checkBirthDate();
                    validateForm();
                  }}
                  type="date"
                  defaultValue={dataFormatada}
                  inputProps={{
                    max: dataFormatada,
                    min: minDate,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <TextField
                  className={classes.input}
                  id="userPhone"
                  name="userPhone"
                  label="Telefone"
                  variant="outlined"
                  style={{ width: "100%", color: "white" }}
                  inputRef={userPhoneRef}
                  onChange={(e) => {
                    checkPhoneFormat();
                    validateForm();
                  }}
                  error={!!errors.userPhoneRef}
                  helperText={errors.userPhoneRef ? errors.userPhoneRef : ""}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <TextField
                  required
                  className={classes.input}
                  id="userEmail"
                  name="userEmail"
                  label="E-mail"
                  variant="outlined"
                  style={{ width: "100%", color: "white" }}
                  inputRef={userEmailRef}
                  onChange={(e) => {
                    checkEmailFormat();
                    validateForm();
                  }}
                  error={!!errors.userEmailRef}
                  helperText={errors.userEmailRef ? errors.userEmailRef : ""}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.input}
                  style={{ minWidth: "100%" }}
                >
                  <InputLabel>Senha *</InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    inputRef={userPasswordRef}
                    onChange={(e) => {
                      checkPasswordFormat();
                      validateForm();
                    }}
                    name="userPassword"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Senha *"
                  />
                  {!!errors.userPasswordRef && (
                    <FormHelperText error id="accountId-error">
                      {errors.userPasswordRef}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  className={classes.input}
                  style={{ minWidth: "100%" }}
                >
                  <InputLabel>Confirmação de senha *</InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    inputRef={userPasswordConfirmationRef}
                    onChange={(e) => {
                      checkPasswordConfirmation();
                      validateForm();
                    }}
                    name="userPasswordConfirmation"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirmação de senha *"
                  />
                  {!!errors.userPasswordConfirmationRef && (
                    <FormHelperText error id="accountId-error">
                      {errors.userPasswordConfirmationRef}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </div>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.input}
              style={{ minWidth: "100%" }}
            >
              <InputLabel htmlFor="bonusAcceptance">
                Como você prefere aceitar bônus?
              </InputLabel>
              <Select
                native
                inputRef={userBonusAcceptanceRef}
                onChange={(e) => {
                  validateForm();
                }}
                label="Como você prefere aceitar bônus?"
                inputProps={{
                  name: "bonusAcceptance",
                  id: "bonusAcceptance",
                }}
              >
                <option value={0}>Sempre perguntar</option>
                <option value={1}>Sempre aceitar</option>
                <option value={2}>Nunca aceitar</option>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <Grid item xs={12}>
                <TextField
                  className={classes.input}
                  id="promoCode"
                  name="promoCode"
                  label="Código promocional/Voucher"
                  variant="outlined"
                  style={{ width: "100%", color: "white", display: `${ promocodeFromUrl ? 'none' : '' }` }}
                  inputRef={userPromoCodeRef}
                  hidden
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: "center" }}>
            <div style={{ margin: "0 auto" }}>
              <FormControlLabel
                className={classes.checkBox}
                checked={checkTerms}
                onChange={(e) => {
                  handleChangeCheckTerms();
                  validateForm();
                }}
                control={<Checkbox name="checkTerms" id="checkTerms" />}
                label="Ao cadastrar-me, eu confirmo que li e aceitei os termos e condições gerais e aceito receber comunicações da Casa de Apostas."
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
                disabled={!validForm}
              >
                Começar Agora!
              </Button>
            </div>
          </Grid>
        </Grid>
      </main>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
        <span style={{ marginLeft: "10px", color: "white" }}>Aguarde</span>
      </Backdrop>
      <form
        action="https://casadeapostas.com/pt-br/Account/Login"
        method="post"
        hidden
        id="myForm"
        target="_parent"
      >
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              id="name-input"
              name="username"
              label="username"
              type="text"
              value={userEmailRef.current.value}
              // onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="password-input"
              name="password"
              label="password"
              type="text"
              value={userPasswordRef.current.value}
              // onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <TextField
              type="hidden"
              name="redir"
              value="hxSeuwWgMFw8y1AuBAygp_YWRBX4J7VHW-lQRX4chkcv"
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              id="submit-button"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
