import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { TextField, Grid } from "@material-ui/core";
import { TextMaskEmail } from "./TextMaskCustom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const useInputsStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 15,
    color: "green",
    "& .MuiFormLabel-root": { color: "white" },
    "& .MuiInputBase-root": { color: "white", height: "2rem" },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white !important" },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      borderColor: "white !important",
    },
  },
}));

export default function FirstPart(props) {
  const classes = useStyles();
  const inputClasses = useInputsStyles();
  const { firstPart, setFirstPart, errors } = props;

  function handleChange(e) {
    const { name, value } = e.target;
    setFirstPart({ ...firstPart, [name]: value });
  }

  return (
    <div style={{ height: 350 }}>
      <Grid container spacing={1}>
        {/* <Grid item xs={12}  >
					<TextField
						required
						className={inputClasses.root}
						id="firstName"
						name="firstName"
						label="Primeiro nome"
						variant="outlined"
						style={{width: '100%', color: 'white'}}
						value={firstPart.firstName}
						onChange={handleChange}
						error={errors.firstName}
						helperText={errors.firstName ? errors.firstName : ''}

						InputLabelProps={{
            shrink: true,
          }}
					/>
				</Grid> */}
        <Grid item xs={12}>
          <TextField
            className={inputClasses.root}
            required
            id="lastName"
            name="lastName"
            label="Sobrenome"
            variant="outlined"
            style={{ width: "100%" }}
            value={firstPart.lastName}
            error={errors.lastName}
            helperText={errors.lastName ? errors.lastName : ""}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* <Grid item xs={12}  >
					<TextField
						required
						id="email"
						name="email"
						
						className={inputClasses.root}
						label="Email"
						variant="outlined"
						style={{width: '100%'}}
						value={firstPart.email}
						error={errors.email}
						helperText={errors.email ? errors.email : ''}

						onChange={handleChange}
						InputProps={{ inputComponent: TextMaskEmail}}

						InputLabelProps={{
            shrink: true,
          }}
					/>						
				</Grid> */}
        <Grid item xs={12}>
          <TextField
            required
            id="logonName"
            name="logonName"
            className={inputClasses.root}
            label="Login"
            variant="outlined"
            style={{ width: "100%" }}
            value={firstPart.logonName}
            error={errors.logonName}
            helperText={errors.logonName ? errors.logonName : ""}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="password"
            name="password"
            type="password"
            className={inputClasses.root}
            label="Senha"
            variant="outlined"
            style={{ width: "100%" }}
            value={firstPart.password}
            error={errors.password}
            helperText={errors.password ? errors.password : ""}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="repassword"
            name="repassword"
            type="password"
            className={inputClasses.root}
            label="Confirmação da senha"
            variant="outlined"
            style={{ width: "100%" }}
            value={firstPart.repassword}
            error={errors.repassword}
            helperText={errors.repassword ? errors.repassword : ""}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
