import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextMaskCpf } from "./TextMaskCustom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  TextField,
  Select,
  Grid,
  InputLabel,
  FormControl,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const useInputsStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 15,
    color: "green",
    "& .MuiFormLabel-root": { color: "white" },
    "& .MuiInputBase-root": { color: "white", height: "2rem" },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white !important" },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      borderColor: "white !important",
    },
    "& .MuiSelect-select:focus": { backgroundColor: "inherit" },
  },
}));

export default function SecondPart(props) {
  const classes = useStyles();
  const inputClasses = useInputsStyles();
  const { secondPart, setSecondPart, errors } = props;

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "birthday") {
      let data = new Date(value);
      data =
        data.getDate() +
        1 +
        "/" +
        (data.getMonth() + 1) +
        "/" +
        data.getFullYear();
      console.log({ value });
      console.log({ data });
      setSecondPart({ ...secondPart, [name]: data.toString() });
    } else {
      setSecondPart({ ...secondPart, [name]: value });
    }
  }

  let data = new Date();
  let dataFormatada =
    data.getFullYear() -
    18 +
    "-" +
    (data.getMonth() + 1) +
    "-" +
    data.getDate();
  // console.log(dataFormatada);
  return (
    <div style={{ height: 350 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            required
            id="birthday"
            name="birthday"
            className={inputClasses.root}
            label="Aniversário"
            variant="outlined"
            style={{ width: "100%", color: "white" }}
            error={errors.birthday}
            helperText={errors.birthday ? errors.birthday : ""}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            inputProps={{
              max: dataFormatada,
            }}
          />
          {/* <DatePicker
            id="birthday"
            name="birthday"
            placeholderText="Aniversário"
            style={{ width: "100%", color: "white" }}
            className={inputClasses.root}
            variant="outlined"
            onChange={handleChange}
          /> */}
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="nationalIdentityId"
            name="nationalIdentityId"
            className={inputClasses.root}
            label="CPF"
            variant="outlined"
            style={{ width: "100%" }}
            value={secondPart.nationalIdentityId}
            error={errors.nationalIdentityId}
            helperText={
              errors.nationalIdentityId ? errors.nationalIdentityId : ""
            }
            onChange={handleChange}
            InputProps={{ inputComponent: TextMaskCpf }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* <Grid item xs={12}  >
					<TextField
						required
						id="phoneNumber"
						name="phoneNumber"
						className={inputClasses.root}
						label="Celular"
						variant="outlined"
						style={{width: '100%'}}
						value={secondPart.phoneNumber}
						error={errors.phoneNumber}
						helperText={errors.phoneNumber ? errors.phoneNumber : ''}


						onChange={handleChange}
						InputProps={{ inputComponent: TextMaskPhone}}

						InputLabelProps={{
            shrink: true,
          }}
					/>						
				</Grid> */}
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="passwordRecoveryQuestionId">
              Pergunta secreta
            </InputLabel>
            <Select
              native
              value={secondPart.passwordRecoveryQuestionId}
              onChange={handleChange}
              label="Pergunta secreta"
              inputProps={{
                name: "securityQuestion",
                id: "passwordRecoveryQuestionId",
                name: "passwordRecoveryQuestionId",
              }}
            >
              <option value={-1}></option>
              <option value={0}>
                Qual o telefone que você lembra da sua infância?
              </option>
              <option value={1}>
                Quem é o seu artor, múdico ou artista favorito?
              </option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="passwordRecoveryAnswer"
            name="passwordRecoveryAnswer"
            className={inputClasses.root}
            label="Resposta"
            variant="outlined"
            style={{ width: "100%" }}
            value={secondPart.passwordRecoveryAnswer}
            error={errors.passwordRecoveryAnswer}
            helperText={
              errors.passwordRecoveryAnswer ? errors.passwordRecoveryAnswer : ""
            }
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
