import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OutlinedGreenButton } from "../../config/PublicThemeProvider";
import { Grid, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import LeadPart from "./LeadPart";
import FirstPart from "./FirstPart";
import SecondPart from "./SecondPart";
import ThirdPart from "./ThirdPart";
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
    fontFamily: "Verdana, Geneva, sans-serif",
    backgroundColor: "rgba(99,216,209,1)",
    borderBottomStyle: "none",
    borderColor: "transparent",
    borderLeftStyle: "none",
    borderRadius: "10px",
    borderRightStyle: "none",
    borderTopStyle: "none",
    borderWidth: "1px",
    paddingTop: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
    paddingLeft: "20px",
    marginTop: "40px",
    marginBottom: "40px",
    maxWidth: "100%",
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    width: "462px",
    color: "white",
    marginLeft: 0,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#7f7f7f",
  },
}));

const steps = ["Parte 1", "Parte 2", "Parte 3", "Parte 4"];
const mailformat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const dateformat =
  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

export default function RegistrationForm(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [redirectTime, setRedirectTime] = useState(null);
  const timer = () => setRedirectTime(redirectTime - 1);
  const [errors, setErrors] = useState({});

  const [leadPart, setLeadPart] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
  });

  const [firstPart, setFirstPart] = useState({
    firstName: "",
    lastName: "",
    email: "",
    logonName: "",
    password: "",
    repassword: "",
  });

  const [secondPart, setSecondPart] = useState({
    birthday: "",
    birthPlace: "",
    nationalIdentityId: "",
    phoneNumber: "",
    passwordRecoveryQuestionId: -1,
    passwordRecoveryAnswer: "",
  });

  const [thirdPart, setThirdPart] = useState({
    promotionCode: props.pageSetting.promo_code,
    bonusAcceptance: 0,
    sendNewsLetterEmail: false,
  });

  useEffect(() => {
    if (redirectTime <= 0) {
      if (activeStep === steps.length) {
        window.location.href = "http://www.casadeapostas.com";
      }
      return;
    }
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [redirectTime]);

  useEffect(() => {
    if (
      activeStep == 0 &&
      leadPart.email &&
      !leadPart.email.match(mailformat)
    ) {
      setErrors({ ...errors, email: "Email inválido" });
    } else {
      setErrors({ ...errors, email: "" });
    }
  }, [leadPart.email]);

  useEffect(() => {
    if (
      (activeStep == 1 &&
        (firstPart.password || firstPart.repassword) &&
        firstPart.password !== firstPart.repassword) ||
      firstPart.password.length < 8
    ) {
      setErrors({
        ...errors,
        repassword: "Confirmação difere de senha de 8 dígitos digitada",
      });
    } else {
      setErrors({ ...errors, repassword: "" });
    }
  }, [firstPart.password, firstPart.repassword]);

  useEffect(() => {
    if (
      activeStep == 1 &&
      firstPart.email &&
      !firstPart.email.match(mailformat)
    ) {
      setErrors({ ...errors, email: "Email inválido" });
    } else {
      setErrors({ ...errors, email: "" });
    }
  }, [firstPart.email]);

  useEffect(() => {
    if (
      activeStep == 2 &&
      secondPart.birthday &&
      !secondPart.birthday.match(dateformat)
    ) {
      setErrors({ ...errors, birthday: "Data inválida" });
    } else {
      setErrors({ ...errors, birthday: "" });
    }
  }, [secondPart.birthday]);

  function canNextStep(step) {
    return (
      (step === 0 && checkLeadPart()) ||
      (step === 1 && checkFirstPart()) ||
      (step === 2 && checkSecondPart()) ||
      (step > 2 && checkThirdPart())
    );
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <LeadPart
            leadPart={leadPart}
            setLeadPart={setLeadPart}
            errors={errors}
          />
        );
      case 1:
        return (
          <FirstPart
            firstPart={firstPart}
            setFirstPart={setFirstPart}
            errors={errors}
          />
        );
      case 2:
        return (
          <SecondPart
            secondPart={secondPart}
            setSecondPart={setSecondPart}
            errors={errors}
          />
        );
      case 3:
        return (
          <ThirdPart
            thirdPart={thirdPart}
            setThirdPart={setThirdPart}
            errors={errors}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  function checkLeadPart() {
    return (
      leadPartPresentField("firstName") &&
      leadPartPresentField("email") &&
      leadPartPresentField("phoneNumber") &&
      leadPart.email.match(mailformat)
    );
  }
  function leadPartPresentField(field) {
    return leadPart[field].length > 0;
  }

  function checkFirstPart() {
    return (
      // firstPartPresentField("firstName") &&
      firstPartPresentField("lastName") &&
      // firstPartPresentField("email") &&
      firstPartPresentField("logonName") &&
      firstPartPresentField("password") &&
      firstPartPresentField("repassword") &&
      firstPartPresentField("password") &&
      firstPartPresentField("repassword") &&
      firstPart.password === firstPart.repassword &&
      firstPart.password.length >= 8
      // && firstPart.email.match(mailformat)
    );
  }
  function firstPartPresentField(field) {
    return firstPart[field].length > 0;
  }

  function checkSecondPart() {
    return (
      secondPartPresentField("birthday") &&
      // secondPartPresentField("birthPlace") &&
      // secondPartPresentField("phoneNumber") &&
      secondPart.passwordRecoveryQuestionId > -1 &&
      secondPartPresentField("passwordRecoveryAnswer") &&
      secondPart.birthday.match(dateformat)
    );
  }
  function secondPartPresentField(field) {
    return secondPart[field].length > 0;
  }

  function checkThirdPart() {
    return true; //thirdPartPresentField("bonusAcceptance");
  }
  function thirdPartPresentField(field) {
    return thirdPart[field].length > -1;
  }

  function handleNext() {
    if (activeStep + 1 === steps.length) {
      criarUsuario();
    } else {
      if (activeStep === 0) {
        const lead = {
          lead: {
            first_name: leadPart.firstName,
            email: leadPart.email,
            phone: leadPart.phoneNumber,
          },
        };
        createLead(lead);
      }

      setActiveStep(activeStep + 1);
    }
  }

  function createLead(obj) {
    axios
      .post(`${window.location.href}admin/leads.json`, obj)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {})
      .then(function () {});
  }

  function updateLead(obj) {
    axios
      .post(`${window.location.href}admin/leads/update_register.json`, obj)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {})
      .then(function () {});
  }

  function criarUsuario() {
    //e.preventDefault();
    document.activeElement.blur();
    toast.dismiss();
    setLoading(true);
    const obj = {
      password: firstPart.password, //
      bonusAcceptance: thirdPart.bonusAcceptance, //
      logonName: firstPart.logonName, //
      promotionCode: thirdPart.promotionCode, //
      profile: {
        firstName: leadPart.firstName, //
        lastName: firstPart.lastName, //
        birthday: secondPart.birthday, //
        birthPlace: "brazil", //secondPart.birthPlace,
        phoneNumbers: [
          {
            id: 1,
            name: leadPart.phoneNumber, //
            type: 0,
          },
        ],
        nationalIdentityId: secondPart.nationalIdentityId.replace(
          /[^\w\s]/gi,
          ""
        ), //
      },
      emailAddresses: leadPart.email, //,
      passwordRecoveryQuestionId: secondPart.passwordRecoveryQuestionId, //
      passwordRecoveryAnswer: secondPart.passwordRecoveryAnswer, //
      sendNewsLetterEmail: true, // thirdPart.sendNewsLetterEmail,
    };
    console.log({ obj });
    axios
      .post(`${props.apiUrl}api/v1/customer/register.json`, obj, {
        headers: { "ca-token": props.apiToken },
      })
      .then(function (response) {
        console.log({ response });
        const lead = {
          lead: {
            first_name: leadPart.firstName,
            email: leadPart.email,
            phone: leadPart.phoneNumber,
          },
        };
        updateLead(lead);
        setActiveStep(activeStep + 1);
      })
      .catch(function (error) {
        console.log(`retornou erro e segue a vida - ${error}`);
        setActiveStep(activeStep + 1);
        setErrors({});
        setRedirectTime(5);
        setLoading(false);
      })
      .then(function () {
        setRedirectTime(5);
        setLoading(false);
      });
  }

  function handleErrorsPage(response_errors) {
    if (
      response_errors.findIndex((item) =>
        Object.keys(firstPart).includes(item)
      ) != -1
    ) {
      setActiveStep(0);
    } else if (
      response_errors.findIndex((item) =>
        Object.keys(secondPart).includes(item)
      ) != -1
    ) {
      setActiveStep(1);
    } else if (
      response_errors.findIndex((item) =>
        Object.keys(thirdPart).includes(item)
      ) != -1
    ) {
      setActiveStep(2);
    }
    return;
  }
  const handleCloseBackdrop = () => {
    setLoading(false);
  };

  return (
    <React.Fragment>
      <main
        className={classes.layout}
        style={{ backgroundColor: `${props.pageSetting.main_color}` }}
      >
        <Grid container>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Grid item xs={12} style={{ height: 538 }}>
                <div style={{ textAlign: "center", marginTop: "50%" }}>
                  <Typography variant="h5">
                    Cadastro efetuado com sucesso!
                  </Typography>
                  <Typography variant="subtitle1">
                    Redirecionando... {redirectTime}{" "}
                  </Typography>
                </div>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <Typography
                  component="h4"
                  variant="h4"
                  style={{
                    fontSize: "24px",
                    fontFamily: "Verdana, Geneva, sans-serif",
                    color: "rgb(255, 255, 255)",
                    lineHeight: "1.25",
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: 20,
                  }}
                >
                  {" "}
                  Ainda não tem cadastro?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {getStepContent(activeStep)}
              </Grid>
              <Grid item xs={12}>
                <div style={{ textAlign: "center" }}>
                  <OutlinedGreenButton
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={!loading && !canNextStep(activeStep)}
                  >
                    {activeStep === steps.length - 1
                      ? "Começar Agora!"
                      : "Próximo Passo >"}
                  </OutlinedGreenButton>
                </div>
              </Grid>
              <Grid item xs={12}>
                <p
                  style={{
                    lineHeight: 1,
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  <span style={{ color: "#FFFFFF", fontSize: "10px" }}>
                    Prometemos não utilizar suas informações de contato para
                    enviar qualquer tipo de SPAM.
                  </span>
                </p>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </main>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
