import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import imgTopo from "../images/header.PNG";
import imgTopoBlack from "../images/header-fundo-preto.PNG";
import imgBg from "../images/bg.JPG";
import axios from "axios";

import { MainContainer } from "../config/PublicThemeProvider";
import RegistrationForm from "../components/RegistrationForm/RegistrationForm.js";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    marginBottom: 20,
    color: "black",

    "& .MuiTypography-body1": {
      textAlign: "center",
      lineHeight: "1.5",
      fontFamily: "Lato, sans-serif",
      fontSize: "18px",
      alignSelf: "center",
    },
  },
}));

export default function Index(props) {
  const classes = useStyles();
  const [pageSetting, setPageSetting] = useState(props.pageSetting || {});

  useEffect(() => {
    if (!props.pageSetting) {
      setPageSetting({
        id: 1,
        main_color: "#63d8d1",
        border_color: "#7ed321",
        banner: imgBg,
        main_text: "teste",
        promo_code: "teste",
      });
    }
  }, []);

  return (
    <div style={{ minWidth: 430 }}>
      <div
        style={{
          // backgroundImage: `url(${pageSetting.banner}), linear-gradient(to bottom, rgba(74,144,226,0) 0%, rgba(74,144,226,0) 100%)`,
          backgroundColor: `${pageSetting.main_color}`,
          backgroundPositionX: "0%",
          backgroundPositionY: "0%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "scroll",
          borderBottomStyle: "solid",
          borderColor: `${pageSetting.border_color}`,
          borderLeftStyle: "none",
          borderRadius: "0px",
          borderRightStyle: "none",
          borderTopStyle: "none",
          borderWidth: "8px",
          textAlign: "center",
        }}
      >
        <img
          src={pageSetting.banner}
          style={{
            maxWidth: "100%",
            maxHeight: "50vh",
            borderBottomStyle: "none",
            borderColor: "#000000",
            borderLeftStyle: "none",
            borderRadius: "0px",
            borderRightStyle: "none",
            borderTopStyle: "none",
            marginBottom: -10,
            borderWidth: "1px",
          }}
        />
      </div>
      <MainContainer style={{ justifyContent: "center", alignItems: "center" }}>
        <Grid
          container
          spacing={3}
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              maxWidth: 480,
              minWidth: 430,
              paddingTop: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              marginTop: "15px",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            <div className={classes.root}>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageSetting.main_text,
                }}
              />

              <br />
              <a
                href="https://casadeapostas.com/"
                target="_blank"
                id="btna"
                title="Any Title"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    backgroundColor: `${pageSetting.main_color}`,
                    borderBottomStyle: "none",
                    borderColor: "#000000",
                    borderLeftStyle: "none",
                    borderRadius: "4px",
                    borderRightStyle: "none",
                    borderTopStyle: "none",
                    borderWidth: 0,
                    color: "#FFFFFF",

                    fontFamily: "Arial, sans-serif",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    height: "44px",
                    justifyContent: "center",
                    lineHeight: "1.42857",
                    marginBottom: "10px",
                    marginTop: "10px",
                    maxWidth: "269px",
                    textAlign: "center",
                    textDecoration: "none",

                    width: "100%",
                  }}
                >
                  Já é cadastrado? Entre Agora
                </Button>
              </a>
            </div>

            {/* </div> */}
          </Grid>
          <Grid item xs={12} sm={6} style={{ alignSelf: "center" }}>
            <div style={{ maxWidth: 340, margin: "0 auto" }}>
              <RegistrationForm
                apiUrl={props.apiUrl}
                apiToken={props.apiToken}
                pageSetting={pageSetting}
              />
            </div>
          </Grid>
        </Grid>
      </MainContainer>

      <div
        style={{
          height: "5.5rem",
          backgroundColor: `${pageSetting.main_color}`,
          borderBottomStyle: "solid",
          borderColor: `${pageSetting.border_color}`,
          borderLeftStyle: "none",
          borderRadius: "1px",
          borderRightStyle: "none",
          borderTopStyle: "solid",
          borderWidth: "8px",
          display: "flex",
        }}
      >
        <div
          style={{ alignSelf: "flex-end", placeSelf: "center", flex: "auto" }}
        >
          <p
            style={{
              margin: 0,
              textAlign: "center",
              lineHeight: "1.5",
              fontFamily: "Lato, sans-serif",
              color: "#000000",
              fontSize: "13px",
              backgroundColor: "transparent",
            }}
          >
            <strong>Casa de Apostas patrocinadora do Jogo Aberto.</strong>
          </p>
        </div>
      </div>
    </div>
  );
}
