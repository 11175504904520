import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Select,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const useInputsStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 15,
    color: "green",
    "& .MuiFormLabel-root": { color: "white" },
    "& .MuiInputBase-root": { color: "white", height: "2.2rem" },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white !important" },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      borderColor: "white !important",
    },
    "& .MuiSelect-select:focus": { backgroundColor: "inherit" },

    "& .MuiFormControlLabel-label": {
      color: "white",
      fontSize: 14,
      fontFamily: "Open Sans, sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
    },
  },
}));

export default function ThirdPart(props) {
  const classes = useStyles();
  const inputClasses = useInputsStyles();
  const { thirdPart, setThirdPart, errors } = props;

  function handleChange(e) {
    let { name, value, checked } = e.target;

    if (name === "sendNewsLetterEmail") {
      value = checked;
    }
    setThirdPart({ ...thirdPart, [name]: value });
  }

  return (
    <div style={{ height: 350 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="promotionCode"
            name="promotionCode"
            className={inputClasses.root}
            label="Promotion Code / Voucher"
            variant="outlined"
            style={{ width: "100%" }}
            value={thirdPart.promotionCode}
            error={errors.promotionCode}
            helperText={errors.promotionCode ? errors.promotionCode : ""}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{ width: "100%" }}
          >
            <InputLabel htmlFor="bonusAcceptance">
              Como você aceitaria bônus?
            </InputLabel>
            <Select
              native
              value={thirdPart.bonusAcceptance}
              onChange={handleChange}
              label="Como você aceitaria bônus?"
              inputProps={{
                name: "bonusAcceptance",
                id: "bonusAcceptance",
              }}
            >
              <option value={0}>Sempre perguntar</option>
              <option value={1}>Sempre aceitar</option>
              <option value={2}>Nunca aceitar</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid>
          <FormGroup
            style={{ marginTop: "1rem" }}
            className={inputClasses.root}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={thirdPart.sendNewsLetterEmail}
                  onChange={handleChange}
                  name="sendNewsLetterEmail"
                  color="primary"
                  style={{
                    fontFamily: "Open Sans, sans-serif",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "normal",
                  }}
                />
              }
              style={{
                fontFamily: "Open Sans, sans-serif",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "normal",
              }}
              label="Eu concordo em receber comunicações e ofertas personalizadas de acordo com meus interesses."
            />
          </FormGroup>
        </Grid>
      </Grid>
    </div>
  );
}
