import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { TextField, Grid } from "@material-ui/core";
import { TextMaskEmail } from "./TextMaskCustom";
import { TextMaskPhone } from "./TextMaskCustom";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const useInputsStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 15,
    color: "green",
    "& .MuiFormLabel-root": { color: "white" },
    "& .MuiInputBase-root": { color: "white", height: "2rem" },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white !important" },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      borderColor: "white !important",
    },
  },
}));

export default function LeadPart(props) {
  const classes = useStyles();
  const inputClasses = useInputsStyles();
  const { leadPart, setLeadPart, errors } = props;

  function handleChange(e) {
    const { name, value } = e.target;
    setLeadPart({ ...leadPart, [name]: value });
  }

  return (
    <div style={{ height: 350 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            required
            className={inputClasses.root}
            id="firstName"
            name="firstName"
            label="Primeiro nome"
            variant="outlined"
            style={{ width: "100%", color: "white" }}
            value={leadPart.firstName}
            onChange={handleChange}
            error={errors.firstName}
            helperText={errors.firstName ? errors.firstName : ""}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            className={inputClasses.root}
            label="Email"
            variant="outlined"
            style={{ width: "100%" }}
            value={leadPart.email}
            error={errors.email}
            helperText={errors.email ? errors.email : ""}
            onChange={handleChange}
            InputProps={{ inputComponent: TextMaskEmail }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="phoneNumber"
            name="phoneNumber"
            className={inputClasses.root}
            label="Celular"
            variant="outlined"
            style={{ width: "100%" }}
            value={leadPart.phoneNumber}
            error={errors.phoneNumber}
            helperText={errors.phoneNumber ? errors.phoneNumber : ""}
            onChange={handleChange}
            InputProps={{ inputComponent: TextMaskPhone }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
